div#footer {
    padding: 4em;
}
#social a {margin: 1em}
.documentFirstHeading::before{
    border-bottom: none !important;}
h1.documentFirstHeading {
    border-bottom: none;
    font-size: 3.5em;
    font-weight: 700
}
h2 {
    font-size:3em
}
h3 {
    font-size:2.5em
}
.block.separator .line::after {
    border-top: 1px solid #fff !important;
}
main img{
    border-radius: 2em
}
.tools-search-wrapper {
    display: none !important;
}
h2 {
    font-size: 2.3em !important;
    font-weight: 700 !important;
    padding-top: 1.3em !important;
    padding-bottom: 0.5em !important;
}
