.cd-header-image img {
  width: 100%
}
/* Start StyleBoxes */
.primary-box {
  background: #65bbec2b;
  padding: 1em;
}

.secondary-box {
  background: #41ccd7;
  padding: 1em;
}

.tertiary-box {
  background: #93e5e8;
  padding: 1em;
}

.gray-box {
  background: #fcf3ec78;
  padding: 1em;
}
.seashell-box {
  background: #fcf3ec;
  padding: 1em;
}
.black-box{
  background: #000;
  color: #fff;
  padding: 1em;
}
.black-box a{
  color: #fff;
}
.tertiary-box a {
  font-weight: bold;
  text-decoration: underline
}
.primary-box a, .secondary-box a {
  text-decoration: underline
}
.video-inner {
  border-radius: 2em;
  overflow: hidden;
}
.primary-box .ui.twelve.column.grid.column-grid,
.secondary-box .ui.twelve.column.grid.column-grid,
.tertiary-box .ui.twelve.column.grid.column-grid,
.gray-box .ui.twelve.column.grid.column-grid,
.seashell-box .ui.twelve.column.grid.column-grid,
.black-box .ui.twelve.column.grid.column-grid {
    max-width:1127px;
    margin-right: auto;
    margin-left: auto;
}
html, body {
    font-size: 16px;
}
